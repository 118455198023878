<template>
  <div class="empty">
    <img
      src="@/assets/img/common/icon36.png"
      class="focus_img"
    />
    <!-- src="https://www.bzwz.com/static/m/images/icon36.png" -->
    <div>
      No
      <span> {{ text }} </span>
      information
      <span v-if="showLive">
        ，Any questions, <a href="https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1">please contact us!</a>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Empty",
  props: {
    text: {
      type: String,
      default: "",
    },
    showLive: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.empty {
  padding-top: 160px;
  text-align: center;
  padding-bottom: 50px;

  color: #9f9f9f;
  font-size: 13px;
}
.empty img {
  width: 132px;
  height: 104px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.empty a {
  font-size: 13px;
  color: #df0024;
}
</style>