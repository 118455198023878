
import { defineComponent, ref, watch } from "vue";
import { Queue } from "@/hooks/usePageTab";
import Preview from "@/components/UI/Preview";
import { async } from "q";
import Toast from "@/components/UI/Toast";
interface FileType {
  src: string;
  file?: File;
}
export default defineComponent({
  name: "Upload",
  emits: ["filechange"],
  components: {
    Preview,
  },
  props: {
    limit: {
      type: Number,
      default: 9,
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, ctx) {
    const showPopup = ref(false);
    const selectedVideo = ref(false);
    const selectedImage = ref(false);
    function handleShowPopup() {
      showPopup.value = true;
    }
    let isMounted = true;
    const list = ref(new Queue<FileType>());
    const imageReg = /image\/(.+)/;
    const videoReg = /video\/(.+)/;
    watch(
      () => {
        return props.modelValue.length;
      },
      (value) => {
        //  初始化文件渲染
        if (isMounted) {
          isMounted = false;
          if (props.modelValue[0]) {
            const videoReg = /\.mp4/g;

            if (videoReg.test((props.modelValue[0] as any).src)) {
              selectedVideo.value = true;
            }
          }
          props.modelValue.forEach((item) => {
            list.value.enQueue({
              src: (item as any).src,
            });
          });
        }
      }
    );

    function closePopup() {
      showPopup.value = false;
    }

    //  获取本地图片地址
    function getObjectURL(file: File) {
      let url = null;
      if ((window as any).createObjectURL != undefined) {
        // basic
        url = (window as any).createObjectURL(file);
      } else if ((window as any).URL != undefined) {
        // mozilla(firefox)
        url = (window as any).URL.createObjectURL(file);
      } else if ((window as any).webkitURL != undefined) {
        // webkit or chrome
        url = (window as any).webkitURL.createObjectURL(file);
      }
      return url;
    }
    function dataURL2File(dataurl: string, filename: string) {
      const arr = dataurl.split(","),
        mime = (arr[0].match(/:(.*?);/) as any)[1],
        bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    //  压缩图片
    function image2Base64(img: any, name: string, ratio?: number) {
      //  压缩比例 ， 默认为 10
      ratio = ratio || 4;
      const reaultWidth = img.width / ratio;
      const reaultHeight = img.height / ratio;

      const canvas = document.createElement("canvas");
      canvas.width = reaultWidth;
      canvas.height = reaultHeight;
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(img, 0, 0, reaultWidth, reaultHeight);
      const dataURL = canvas.toDataURL("image/png");
      return dataURL2File(dataURL, name);
    }
    function createImgSource(url: string) {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = function () {
          resolve(img);
        };
        img.onerror = function () {
          resolve("error");
        };
      });
    }
    async function onChange(e: any) {
      const file = e.target.files[0];
      if (!file) {
        closePopup();
        return false;
      }

      const src = getObjectURL(file);

      if (imageReg.test(file.type)) {
        selectedImage.value = true;

        const source = await createImgSource(src);
        const compressFile = image2Base64(source, file.name);
        list.value.enQueue({
          src,
          file: compressFile,
        });
      }
      if (videoReg.test(file.type)) {
        
        //  若选择图片之后再次选择视频则忽视此次操作，苹果相册可以录像
        if(list.value.size() > 0 ){
            closePopup();
            Toast({
              title:'仅能上传图片或视频！',
              type:'warning',
              duration:2000
            })
            return false
        }
        selectedVideo.value = true;
        list.value.enQueue({
          src,
          file: file,
        });
      }

      closePopup();
      isMounted = false;
      ctx.emit("filechange", list.value.value());
    }
    function deleteItem(index: number) {
      list.value.removeAtPos(index);
      if (list.value.isEmptyQueue()) {
        selectedImage.value = false;
        selectedVideo.value = false;
      }
      ctx.emit("filechange", list.value.value());
    }

    const previewing = ref(false);
    const currentIndex = ref(0);
    function closePreview() {
      previewing.value = false;
    }

    function onPreview(index: number) {
      currentIndex.value = index;
      previewing.value = true;
    }

    return {
      showPopup,
      handleShowPopup,
      closePopup,
      onChange,
      list,
      deleteItem,
      selectedVideo,
      selectedImage,
      closePreview,
      currentIndex,
      previewing,
      onPreview,
    };
  },
});
