

import Upload from './src/Upload.vue'
import { App } from 'vue'


Upload.install = (app: App) => {
    app.component(Upload.name, Upload)
}

export default Upload
