
import { defineComponent } from "vue";
export default defineComponent({
  name: "Empty",
  props: {
    text: {
      type: String,
      default: "",
    },
    showLive: {
      type: Boolean,
      default: true,
    },
  },
});
