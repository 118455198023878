<template>
  <div class="post-topics-file">
    <div
      v-show="selectedImage || (list.value().length && !selectedVideo)"
      class="post-topic-img post-topic-action"
      v-for="(item, index) in list.value()"
      @click="onPreview(index)"
      :key="index"
      :style="{ backgroundImage: `url(${item.src})` }"
    >
      <img
        class="close"
        @click.stop="deleteItem(index)"
        src="./img/close3.png"
        alt=""
      />
    </div>
    <div
      class="post-topics-file-img post-topic-img"
      v-show="
        (selectedImage && list.size() < limit) ||
        (!selectedVideo && !selectedImage)
      "
      @click="handleShowPopup()"
    >
      <img
        src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/upload-icon-20200909.png"
      />
    </div>
    <div class="post-topics-video" v-show="selectedVideo">
      <!-- <img class="play" src="https://www.bzwz.com/static/m/images/play.png" /> -->
      <video
        v-for="(item, index) in list.value()"
        :key="index"
        :src="item.src"
        controls
      ></video>
      <img class="close" @click.stop="deleteItem(0)" src="./img/close.png" alt="" />
    </div>
  </div>
  <preview
    v-if="list.size() && previewing"
    @close="closePreview"
    :imgList="list.value()"
    :currentIndex="currentIndex"
  ></preview>
  <teleport to="#toast">
    <div
      class="popup-overlay popup-overlay-to"
      @click="closePopup()"
      v-show="showPopup"
    ></div>
    <transition tag="div" name="popup">
      <div class="popup-content popup-content-to" v-show="showPopup">
        <div class="popup-shot button" v-show="!selectedVideo">
          拍照
          <input
            type="file"
            accept="image/*"
            id="popup-camera"
            capture="camera"
            @change="onChange"
          />
        </div>
        <div
          class="popup-videotape button"
          v-show="!selectedImage && !selectedVideo"
        >
          摄像
          <input
            type="file"
            accept="video/*"
            id="popup-camcorder"
            capture="camcorder"
            @change="onChange"
          />
        </div>
        <div class="popup-choice button">
          从相册选择
          <input
            type="file"
            accept="image/*,video/*"
            @change="onChange"
            id="popup-file"
          />
        </div>
        <div class="popup-sheet"></div>
        <div class="popup-cancel button" @click="closePopup()">cancel</div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { Queue } from "@/hooks/usePageTab";
import Preview from "@/components/UI/Preview";
import { async } from "q";
import Toast from "@/components/UI/Toast";
interface FileType {
  src: string;
  file?: File;
}
export default defineComponent({
  name: "Upload",
  emits: ["filechange"],
  components: {
    Preview,
  },
  props: {
    limit: {
      type: Number,
      default: 9,
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, ctx) {
    const showPopup = ref(false);
    const selectedVideo = ref(false);
    const selectedImage = ref(false);
    function handleShowPopup() {
      showPopup.value = true;
    }
    let isMounted = true;
    const list = ref(new Queue<FileType>());
    const imageReg = /image\/(.+)/;
    const videoReg = /video\/(.+)/;
    watch(
      () => {
        return props.modelValue.length;
      },
      (value) => {
        //  初始化文件渲染
        if (isMounted) {
          isMounted = false;
          if (props.modelValue[0]) {
            const videoReg = /\.mp4/g;

            if (videoReg.test((props.modelValue[0] as any).src)) {
              selectedVideo.value = true;
            }
          }
          props.modelValue.forEach((item) => {
            list.value.enQueue({
              src: (item as any).src,
            });
          });
        }
      }
    );

    function closePopup() {
      showPopup.value = false;
    }

    //  获取本地图片地址
    function getObjectURL(file: File) {
      let url = null;
      if ((window as any).createObjectURL != undefined) {
        // basic
        url = (window as any).createObjectURL(file);
      } else if ((window as any).URL != undefined) {
        // mozilla(firefox)
        url = (window as any).URL.createObjectURL(file);
      } else if ((window as any).webkitURL != undefined) {
        // webkit or chrome
        url = (window as any).webkitURL.createObjectURL(file);
      }
      return url;
    }
    function dataURL2File(dataurl: string, filename: string) {
      const arr = dataurl.split(","),
        mime = (arr[0].match(/:(.*?);/) as any)[1],
        bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    //  压缩图片
    function image2Base64(img: any, name: string, ratio?: number) {
      //  压缩比例 ， 默认为 10
      ratio = ratio || 4;
      const reaultWidth = img.width / ratio;
      const reaultHeight = img.height / ratio;

      const canvas = document.createElement("canvas");
      canvas.width = reaultWidth;
      canvas.height = reaultHeight;
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(img, 0, 0, reaultWidth, reaultHeight);
      const dataURL = canvas.toDataURL("image/png");
      return dataURL2File(dataURL, name);
    }
    function createImgSource(url: string) {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = function () {
          resolve(img);
        };
        img.onerror = function () {
          resolve("error");
        };
      });
    }
    async function onChange(e: any) {
      const file = e.target.files[0];
      if (!file) {
        closePopup();
        return false;
      }

      const src = getObjectURL(file);

      if (imageReg.test(file.type)) {
        selectedImage.value = true;

        const source = await createImgSource(src);
        const compressFile = image2Base64(source, file.name);
        list.value.enQueue({
          src,
          file: compressFile,
        });
      }
      if (videoReg.test(file.type)) {
        
        //  若选择图片之后再次选择视频则忽视此次操作，苹果相册可以录像
        if(list.value.size() > 0 ){
            closePopup();
            Toast({
              title:'仅能上传图片或视频！',
              type:'warning',
              duration:2000
            })
            return false
        }
        selectedVideo.value = true;
        list.value.enQueue({
          src,
          file: file,
        });
      }

      closePopup();
      isMounted = false;
      ctx.emit("filechange", list.value.value());
    }
    function deleteItem(index: number) {
      list.value.removeAtPos(index);
      if (list.value.isEmptyQueue()) {
        selectedImage.value = false;
        selectedVideo.value = false;
      }
      ctx.emit("filechange", list.value.value());
    }

    const previewing = ref(false);
    const currentIndex = ref(0);
    function closePreview() {
      previewing.value = false;
    }

    function onPreview(index: number) {
      currentIndex.value = index;
      previewing.value = true;
    }

    return {
      showPopup,
      handleShowPopup,
      closePopup,
      onChange,
      list,
      deleteItem,
      selectedVideo,
      selectedImage,
      closePreview,
      currentIndex,
      previewing,
      onPreview,
    };
  },
});
</script>


<style lang="scss" scoped>
.post {
  padding: 10px;
}

.post-topics-file {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 0;
  box-sizing: border-box;
}

.post-topic-img {
  width: 31%;
  height: 27vw;
  background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://shijiaoke.oss-cn-beijing.aliyuncs.com/PIC/BWL7011-2016_pic.jpg);
}

.post-topic-img:nth-child(3n-1) {
  margin: 0 10px;
}

.post-topic-img:nth-child(n + 4) {
  margin-top: 10px;
}

.post-topics-file-img {
  background-color: #f6f6f6;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  background-image: none;
}

.post-topics-file-img img {
  width: 24px;
  height: 24px;
}

.post-topics-file input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  display: none;
}

.post-topics-video .play {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  top: 50%;
  margin-top: -16px;
}

.post-topics-video video {
  max-width: 100%;
  object-fit: fill;
  width: 100%;
  background: #000000;
}

.post-topics-video {
  position: relative;
  width: 50%;
  overflow: hidden;
  .close {
    right: 0;
    top: 0;
    margin-left: 0;
    margin-top: 0;
  }
}

.popup-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 100%;
  z-index: 12;
  width: 100%;
  height: 100%;
}

.popup-content {
  position: fixed;
  left: 0;
  bottom: 0;
  max-height: 100%;
  width: 100%;
  border-radius: 15px 15px 0 0;
  -webkit-overflow-scrolling: touch;
  z-index: 13;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.popup-sheet {
  display: block;
  height: 8px;
  background-color: #f7f8fa;
}

.popup-content .button:nth-child(1) {
  border-radius: 15px 15px 0 0;
}

.popup-content .button:nth-child(2),
.popup-content .button:nth-child(3) {
  border-top: 1px solid #eee;
}

.popup-content .button {
  display: block;
  width: 100%;
  padding: 13.988px 16px;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  line-height: 22px;
  color: rgb(50, 50, 51);
  position: relative;
  text-align: center;
}

.popup-content .button input {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.popup-enter-active,
.popup-leave-active {
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.popup-enter-from,
.popup-leave-to {
  transform: translateY(100%);
}
.post-topic-action,
.post-topics-video {
  background-position: center center;
  position: relative;
  .close {
    position: absolute;
    width: 16px;
    height: 16.5px;
    right: 0px;
    top: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.4);
    padding-bottom: 1px;
  }
}
</style>